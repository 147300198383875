<template>
  <button
    class="sl__button next-button"
    :aria-label="buttonLabel + ' button'"
    :disabled="disabled"
    @click.prevent="next"
  >
    {{ buttonLabel }}
  </button>
</template>

<script>
export default {
  name: 'NextButton',
  props: {
    buttonLabel: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    next() {
      this.$emit('next')
    },
  },
}
</script>

<style lang="scss" scoped>
.next-button {
  height: 3.0em;
  border: 0;
  border-radius: 0.25em;
  color: white;
  background: $sl__teal1;

  &:hover,
  &:active {
    color: white;
    background: $sl__teal2;
    border-color: $sl__teal1;
  }

  &:disabled {
    color: white;
    background: $sl__teal1;
    opacity: 0.65;
  }
}
</style>
